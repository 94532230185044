var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "", "fill-height": "" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height" },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", attrs: { cols: "12" } },
            [
              _c(
                "v-parallax",
                {
                  staticClass: "fill-height pa-0",
                  attrs: {
                    src: require("../../../assets/images/signin-background.jpg"),
                    height: "100%",
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mr-n3",
                      attrs: { justify: "end", dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pa-0 fill-height",
                          class: {
                            "py-12 px-10 pl-14":
                              !_vm.$vuetify.breakpoint.mobile ||
                              _vm.$vuetify.breakpoint.mdOnly,
                          },
                          attrs: {
                            cols: "12",
                            sm: "12",
                            lg: "5",
                            md: "5",
                            xl: "4",
                            "align-self": "end",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass:
                                "rounded-lg pa-0 fill-height mx-auto",
                              class: {
                                "fill-height": _vm.$vuetify.breakpoint.mobile,
                              },
                              attrs: {
                                "max-width": !_vm.$vuetify.breakpoint.mobile
                                  ? "500"
                                  : null,
                              },
                            },
                            [
                              _vm.isSignIn
                                ? _c("SignIn", {
                                    ref: "signIn",
                                    attrs: {
                                      "is-forgot-password":
                                        _vm.isForgotPassword,
                                    },
                                    on: {
                                      userAuthSuccess: _vm.userAuthorization,
                                      forgotPasswordClick:
                                        _vm.showForgotPassword,
                                      mfaCodeSent: _vm.showMFA,
                                    },
                                  })
                                : _vm._e(),
                              _vm.isForgotPassword
                                ? _c("ForgotPassword", {
                                    on: {
                                      onBackButtonClick: _vm.onBackButtonClick,
                                      emailSent: _vm.emailSent,
                                    },
                                  })
                                : _vm._e(),
                              _vm.isEmailSent
                                ? _c("ConfirmationMessage", {
                                    attrs: {
                                      icon: _vm.fogotPasswordEmailSentObject
                                        .icon,
                                      text: _vm.fogotPasswordEmailSentObject
                                        .text,
                                      button:
                                        _vm.fogotPasswordEmailSentObject.button,
                                      "show-logo":
                                        _vm.fogotPasswordEmailSentObject
                                          .showLogo,
                                      "background-color":
                                        _vm.fogotPasswordEmailSentObject
                                          .backgroundColor,
                                    },
                                    on: {
                                      onClickBackToSignin:
                                        _vm.onClickBackToSignin,
                                    },
                                  })
                                : _vm._e(),
                              _vm.isMFA
                                ? _c("MFA", {
                                    staticClass: "pt-16",
                                    attrs: {
                                      "is-signin": true,
                                      password: _vm.password,
                                      "margin-style": "mb-16",
                                    },
                                    on: { userVerified: _vm.onMFAVerification },
                                  })
                                : _vm._e(),
                              _c("CompanyFooter", {
                                attrs: {
                                  "show-full-width": true,
                                  "margin-style": "mb-5",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }