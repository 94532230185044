<template>
  <v-container fluid fill-height class="pa-0">
    <v-row class="fill-height">
      <v-col cols="12" class="pa-0">
        <v-parallax
          class="fill-height pa-0"
          src="../../../assets/images/signin-background.jpg"
          height="100%"
        >
          <v-row justify="end" dense class="mr-n3">
            <v-col
              cols="12"
              sm="12"
              lg="5"
              md="5"
              xl="4"
              class="pa-0 fill-height"
              align-self="end"
              :class="{
                'py-12 px-10 pl-14':
                  !$vuetify.breakpoint.mobile || $vuetify.breakpoint.mdOnly,
              }"
            >
              <v-card
                class="rounded-lg pa-0 fill-height mx-auto"
                :max-width="!$vuetify.breakpoint.mobile ? '500' : null"
                :class="{
                  'fill-height': $vuetify.breakpoint.mobile,
                }"
              >
                <SignIn
                  v-if="isSignIn"
                  ref="signIn"
                  :is-forgot-password="isForgotPassword"
                  @userAuthSuccess="userAuthorization"
                  @forgotPasswordClick="showForgotPassword"
                  @mfaCodeSent="showMFA"
                />
                <ForgotPassword
                  v-if="isForgotPassword"
                  @onBackButtonClick="onBackButtonClick"
                  @emailSent="emailSent"
                />
                <ConfirmationMessage
                  v-if="isEmailSent"
                  :icon="fogotPasswordEmailSentObject.icon"
                  :text="fogotPasswordEmailSentObject.text"
                  :button="fogotPasswordEmailSentObject.button"
                  :show-logo="fogotPasswordEmailSentObject.showLogo"
                  :background-color="
                    fogotPasswordEmailSentObject.backgroundColor
                  "
                  @onClickBackToSignin="onClickBackToSignin"
                />
                <MFA
                  v-if="isMFA"
                  :is-signin="true"
                  :password="password"
                  class="pt-16"
                  margin-style="mb-16"
                  @userVerified="onMFAVerification"
                />
                <CompanyFooter :show-full-width="true" margin-style="mb-5" />
              </v-card>
            </v-col>
          </v-row>
        </v-parallax> </v-col
    ></v-row>
  </v-container>
</template>

<script>
import { getKeys } from "@/store/utils/utils";
import MFA from "@/components/molecules/MFA.vue";
export default {
  name: "SignInMain",
  components: {
    CompanyFooter : () => import("../../molecules/CompanyFooter.vue"),
    SignIn : () => import("./SignIn.vue"),
    ConfirmationMessage : () => import("../../molecules/ConfirmationMessage.vue"),
    ForgotPassword : () => import("../password/forgot-password.vue"),
    MFA,
  },
  props: {},
  data: () => ({
    isForgotPassword: false,
    isSignIn: true,
    isEmailSent: false,
    isMFA: false,
    password: "",
    fogotPasswordEmailSentObject: {
      footerLink: {
        footerLinkLabel: "noThanks",
        footerLinkPath: "/signin",
      },
      text: {
        bodyLabel: "We sent an email to <strong>test@gmail.com</strong> with a link to reset your password.",
        headerLabel: "Email Sent!",
        bodyLabelParams: {
          email: "test@gmail.com",
        },
      },
      hrLabel: "or",
      button: {
        buttonLabel: "Back to Sign In",
        buttonColor: "#0073D1",
        buttonOutlined: true,
        buttonEvent: "onClickBackToSignin",
      },
      icon: {
        iconName: "",
        iconColor: "",
        isIcon: false,
      },
      showLogo: false,
      backgroundColor: false,
    },
  }),
  created() {
    const urlParams = new URLSearchParams(location.search);
    const keys = getKeys(urlParams);
    if (keys.length && keys.includes("passwordLinkInvalid")) {
      this.showForgotPassword();
    }
  },
  methods: {
    userAuthorization() {
      console.log("User Authorized");
    },
    showMFA() {
      this.password = this.$refs.signIn.password;
      this.isMFA = true;
      this.isForgotPassword = false;
      this.isSignIn = false;
      this.isEmailSent = false;
    },
    onMFAVerification() {
      console.log("MFA verified");
    },
    showForgotPassword() {
      this.isForgotPassword = !this.isForgotPassword;
      this.isSignIn = !this.isSignIn;
    },
    onBackButtonClick() {
      this.isForgotPassword = !this.isForgotPassword;
      this.isSignIn = !this.isSignIn;
    },
    onClickBackToSignin() {
      this.isForgotPassword = false;
      this.isSignIn = true;
      this.isEmailSent = false;
    },
    emailSent(email) {
      this.fogotPasswordEmailSentObject.text.bodyLabel = `We sent an email to <strong>${email}</strong> with a link to reset your password.`;
      this.fogotPasswordEmailSentObject.text.bodyLabelParams.email = email;
      this.isForgotPassword = false;
      this.isSignIn = false;
      this.isEmailSent = !this.isEmailSent;
    },
  },
};
</script>

<style scoped></style>
